// src/services/wordpressService.js
import axios from 'axios';
import clientConfig from "../client-config";


const API_BASE_URL = clientConfig.siteUrl + '/wp-json/wp/v2';

export const fetchCategory = async (categoryNameOrSlug) => {
    try {
        const response = await axios.get(
            `${API_BASE_URL}/categories?slug=${categoryNameOrSlug}`
        );

        if (response.data.length > 0) {
            return response.data[0]; // Return the first matching category ID
        } else {
            throw new Error('Category not found');
        }
    } catch (error) {
        console.error('Error fetching category ID:', error);
        throw error;
    }
};

export const fetchPostsByCategoryId = async (categoryId, perPage = 10, page = 1) => {
    try {
        const response = await axios.get(
            `${API_BASE_URL}/posts?categories=${categoryId}&per_page=${perPage}&page=${page}&content=false&_embed`
        );
        return {
            data: response.data,
            total: response.headers['x-wp-total'], // Total number of categories
            totalPages: response.headers['x-wp-totalpages'], // Total number of pages
        };
    } catch (error) {
        console.error('Error fetching posts:', error);
        throw error;
    }
};


export const postInquiry = async (body) => {
    //url: `http://api2.gs1mongolia.org/v1.0/inquiries`,
    // url: `http://localhost:3000/v1.0/inquiries`,

    try {

        const response = await axios({
            method: 'POST',
            url: `https://api2.gs1mongolia.org/v1.0/inquiries`,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            data: JSON.stringify(body)
        });
        return response.data;

    } catch (error) {
        console.error('Error fetching posts:', error);
        throw error;
    }
};