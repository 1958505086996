import React from "react";
import {Link} from "@reach/router";
import "../styles/wordpress.scss"
import juram from "../docs/juram.pdf"
import memberDoc from "../docs/member.pdf"
import productDoc from "../docs/product.pdf"
import feesDoc from "../docs/fees.pdf"
import glnDoc from "../docs/gln.pdf"
import ssccDoc from "../docs/sscc.pdf"
import packageJson from '../../package.json';
import memberIndividualDoc from "../docs/member-ind.pdf";

export default () => (
    <div style={{backgroundColor: "#ededed"}}>
        <div className="container">
            <footer>
                <div className="row">
                    <div className="col-xs-12 col-sm-6 col-md-2">
                        <h4>Бидний Тухай</h4>
                        <ul>
                            <li>
                                <Link to="/ab/aboutus">Танилцуулга</Link>
                            </li>
                            <li>
                                <Link to="/ab/greetings">Мэндчилгээ</Link>
                            </li>
                            <li>
                                <Link to="/ab/history">Он цагийн товчоон</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-2">
                        <h4>Үйлчилгээ</h4>
                        <ul>
                            <li>
                                <Link to="/srv/service_gs1app">
                                    GS1 Mongolia APP
                                </Link>
                            </li>
                            <li>
                                <Link to="/srv/service_experts">GS1 experts team</Link>
                            </li>
                            <li>
                                <Link to="/srv/service_logoplacement">Лого байршуулах санал</Link>
                            </li>
                            <li>
                                <Link to="/srv/service_training">Сургалт</Link>
                            </li>
                            <li>
                                <Link to="/service/brochure">Ном гарын авлага</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-2">
                        <h4>Мэдээ Мэдээлэл</h4>
                        <ul>
                            <li>
                                <a href="/news">Мэдээ, Мэдээлэл</a>
                            </li>
                            <li>
                                <a href="/events">Арга Хэмжээ</a>
                            </li>
                            <li>
                                <a href="/newsletter">Электрон сонин</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-2">
                        <h4 style={{fontSize: "18px"}}>Гишүүнчлэл</h4>
                        <ul>
                            <li>
                                <a href={juram}
                                   download>Журам</a>
                            </li>
                            <li>
                                <a href={memberDoc}
                                   download>Байгууллагын анкет</a>
                            </li>
                            <li>
                                <a href={memberIndividualDoc}
                                   download>Хувь хүний анкет
                                </a>
                            </li>

                            <li>
                                <a href={productDoc}
                                   download>Бүтээгдэхүүний анкет</a>
                            </li>
                            <li>
                                <a href={feesDoc}
                                   download>Гишүүнчлэлийн төлбөр</a>
                            </li>
                            <li>
                                <a href={glnDoc}
                                   download>GLN анкет</a>
                            </li>
                            <li>
                                <a href={ssccDoc}
                                   download>SSCC анкет</a>
                            </li>
                        </ul>
                    </div>

                    <div className="col-xs-12 col-sm-6 col-md-2">
                        <h4 style={{fontSize: "18px"}}>Бусад</h4>
                        <ul>
                            <li><Link to="/asuult">Асуулт Хариулт</Link></li>
                            <li><Link to="/location">Холбоо барих</Link></li>
                            <li><Link to="/contact">Санал хүсэлт</Link></li>
                            <li><a href="https://gs1.org">English</a></li>
                        </ul>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-2">
                        <div className="social">
                            <h4>Холбоо барих:</h4>
                            <p><span className="primary">"GS1 Монгол"</span><br/>
                                "Морьтон" цогцолбор, 9 давхар,
                                19-р хороолол, 3-р хороо, ХУД,
                                УБ хот, Монгол улс{" "}<br/>
                                <span className="primary">Утас:</span> +976-77 000 865. <span
                                    className="primary">Email:</span>{" "}
                                <a href="mailto: info@gs1mn.org"> info@gs1mn.org</a></p>
                            <hr/>
                            <a href="https://www.facebook.com/GS1Mongolia" style={{margin: "5px"}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                                     className="bi bi-facebook" viewBox="0 0 16 16">
                                    <path
                                        d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
                                </svg>
                            </a>
                            <a href="https://twitter.com/MongoliaGs1" style={{margin: "5px"}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                                     className="bi bi-twitter-x" viewBox="0 0 16 16">
                                    <path
                                        d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                                </svg>
                            </a>
                            <a href="https://www.youtube.com/channel/UClFCV8tfts6DueiJzW7IqYQ" style={{margin: "5px"}}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                                     className="bi bi-youtube" viewBox="0 0 16 16">
                                    <path
                                        d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xs-12">
                        &nbsp;© 2020-{new Date().getFullYear()} ”GS1 Монгол” ТББ. Зохиогчийн эрх хуулиар хамгаалагдсан.
                        V.{packageJson.version}
                    </div>
                </div>
            </footer>
        </div>
    </div>
)

