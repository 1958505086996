import React, {useEffect, useState} from "react";
import {Link, useLocation} from "@reach/router";
import Layout from "../components/layout";
import axios from "axios";
import clientConfig from "../client-config";
import qassets from "../qassets";
import Loader from "../images/loading.gif";
import {fetchCategory, fetchPostsByCategoryId} from "../services/wordpressService";

export default ({asuult}) => {
    const [loading, setLoading] = useState(false);
    const [posts, setPosts] = useState([]);
    const [error, setError] = useState("");
    //const posts = data.allWordpressPost.edges
    const location = useLocation();

    const o = qassets[asuult];
    const cat = o["cat"];
    const title = o["title"];


    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                // Fetch category ID
                const category = await fetchCategory(cat);

                // Fetch posts for the category
                const {data} = await fetchPostsByCategoryId(category.id, 100, 1);
                setPosts(data);
                setLoading(false);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [cat]);


    // const wordPressSiteURL = clientConfig.siteUrl;
    // const url = `${wordPressSiteURL}/wp-json/better-rest-endpoints/v1/category/${cat}?content=false&media=false&per_page=100`;
    //
    // useEffect(() => {
    //     setLoading(true);
    //
    //     axios.get(url)
    //         .then(res => {
    //             if (res.data.length) {
    //                 setLoading(false);
    //                 setPosts(res.data)
    //
    //             } else {
    //                 setLoading(false);
    //                 setError("No news found");
    //             }
    //         })
    //         .catch(err => {
    //             setLoading(false);
    //             setError(err.message);
    //         });
    //
    // }, [cat]);

    const createMarkup = (data) => ({
        __html: data
    });

    return (
        <Layout aPageProp={""}>
            <div style={{marginBottom: "50px"}}>
                <h1>{title}</h1>
                {error && <div className="alert alert-danger" dangerouslySetInnerHTML={createMarkup(error)}/>}
                {posts.length ? (

                    <ul>
                        {posts.map(node => {
                            const linkTitle = node.title.rendered || node.slug
                            return (
                                <li key={node.slug}>

                                    <Link style={{boxShadow: 'none'}} to={"/q/" + node.id}>
                                        {/*+ decodeURIComponent(node.slug)}>*/}
                                        {linkTitle}
                                    </Link>

                                </li>
                            );
                        })}
                    </ul>) : ''}
            </div>
            {loading && <img className="loader" src={Loader} alt="Loader"/>}
        </Layout>
    )

}

