import React, {useEffect, useState} from "react";
import {Link, useLocation} from "@reach/router";
import Layout from "../components/layout";
import he from "he";
import clientConfig from '../client-config';
import Loader from "../images/loading.gif";
import dayjs from "dayjs"
import {fetchCategory, fetchPostsByCategoryId} from "../services/wordpressService";

export default ({ page}) => {
    const [loading, setLoading] = useState(false);
    const [posts, setPosts] = useState([]);
    const [error, setError] = useState("");
    const [totalPages, setTotalPages] = useState(0);
    const [total, setTotal] = useState(0);
    const [isFirst, setIsFirst] = useState(true);
    const [isLast, setIsLast] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [prevPage, setPrevPage] = useState("news");
    const [nextPage, setNextPage] = useState("news");


    //const posts = data.allWordpressPost.edges
    const location = useLocation();
    //const {currentPage, numPages, cat, title} = pageContext
    //const currentPage = 0;
    //const numPages = 1;
    const cat = "news";
    const title = "Мэдээ Мэдээлэл"

    let prefix = ""
    if (cat === "news") {
        prefix = "n";
    } else if (cat === "events") {
        prefix = "n";
    }
    //let setCurrentPage = 0
    let p = 1;
    if (page !== undefined) {
        p = parseInt(page);
        //setCurrentPage( page);
    }
    let perPage = 25;
    if (page !== undefined) {
        p = parseInt(page);
        //setCurrentPage( page);
    }

    // const wordPressSiteURL = clientConfig.siteUrl;
    //
    // const url = `${wordPressSiteURL}/wp-json/better-rest-endpoints/v1/category/news?content=false&media=false&page=${p}`;
    // const url2 = `${wordPressSiteURL}/wp-json/wp/v2/posts`
    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                // Fetch category ID
                const category = await fetchCategory('news');

                // Fetch posts for the category
                const {data, total, totalPages} = await fetchPostsByCategoryId(category.id, 25, p);
                setPosts(data);
                // let tp = parseInt(res.headers["x-wp-totalpages"], 10);
                // let t = parseInt(res.headers["x-wp-total"], 10);
                setTotalPages(totalPages);
                setTotal(total);
                // //this.setState( { loading: false, posts: res.data } );
                setIsFirst(p === 1);
                setIsLast(p === totalPages);
                setPrevPage(p - 1 === 1 ? `/news` : `/news/` + (p - 1).toString());
                setNextPage(`/news/` + (p + 1).toString());
                if (page !== undefined) {
                    setCurrentPage(parseInt(page));
                }
                setLoading(false);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
        // axios.get(url)
        //     .then(res => {
        //         if (res.data.length) {
        //             setLoading(false);
        //             setPosts(res.data)
        //             let tp = parseInt(res.headers["x-wp-totalpages"], 10);
        //             let t = parseInt(res.headers["x-wp-total"], 10);
        //             setTotalPages(tp);
        //             setTotal(t);
        //             //this.setState( { loading: false, posts: res.data } );
        //             setIsFirst(p === 1);
        //             setIsLast(p === tp);
        //             setPrevPage(p - 1 === 1 ? `/news` : `/news/` + (p - 1).toString());
        //             setNextPage(`/news/` + (p + 1).toString());
        //             if (page !== undefined) {
        //                 setCurrentPage(parseInt(page));
        //             }
        //
        //         } else {
        //             setLoading(false);
        //             setError("No news found");
        //         }
        //     })
        //     .catch(err => {
        //         setLoading(false);
        //         setError(err.message);
        //     });

    }, [p]);

    const createMarkup = (data) => ({
        __html: data
    });

    return (
        <Layout aPageProp={location.pathname}>
            <h3>{title}</h3>
            {error && <div className="alert alert-danger" dangerouslySetInnerHTML={createMarkup(error)}/>}
            {posts.length ? (
                <div>
                    <table className="table table-bordered table-striped  table-dark">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Мэдээллийн гарчиг</th>
                            <th style={{width: "15%"}}>Огноо</th>
                        </tr>
                        </thead>
                        <tbody>
                        {posts.map((node, index) => {
                            const title = node.title.rendered || node.slug
                            return (
                                <tr key={node.slug}>
                                    <td>{(p - 1) * 10 + index + 1}</td>
                                    <td>
                                        <Link style={{boxShadow: 'none'}}
                                              to={"/" + cat + "/news/p/" + node.id}>
                                            {he.decode(title)}
                                        </Link>
                                        {/*<Link style={{boxShadow: 'none'}}*/}
                                        {/*      to={"/" + cat + "/news/p/" + decodeURIComponent(`${node.slug}/`)}>*/}
                                        {/*    {he.decode(title)}*/}
                                        {/*</Link>*/}
                                    </td>
                                    <td>{dayjs(node.date).format("YYYY-MM-DD")}</td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>

                    <ul
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            listStyle: 'none',
                            padding: 0,
                            paddingBottom: "25px"
                        }}
                    >
                        {!isFirst && (
                            <Link to={prevPage} rel="prev">
                                Өмнөх
                            </Link>
                        )}
                        {Array.from({length: totalPages}, (_, i) => (
                            <li
                                key={`pagination-number${i + 1}`}
                                style={{
                                    margin: 0,
                                }}
                            >
                                <Link
                                    to={"/" + "news" + "/" + `${i === 0 ? '' : i + 1}`}
                                    style={{
                                        padding: '10px',
                                        textDecoration: 'none',
                                        color: i + 1 === p ? '#ffffff' : '',
                                        background: i + 1 === p ? '#007acc' : '',
                                    }}
                                >
                                    {i + 1}
                                </Link>
                            </li>
                        ))}
                        {!isLast && (
                            <Link to={nextPage} rel="next">
                                Дараах
                            </Link>
                        )}
                    </ul>
                </div>
            ) : ''}
            {loading && <img className="loader" src={Loader} alt="Loader"/>}
        </Layout>
    )
}
