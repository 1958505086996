// src/utils/postUtils.js

export const getFeaturedLargeImageUrl = (post) => {
    const sizes = post?._embedded?.['wp:featuredmedia']?.[0]?.media_details?.sizes;
    return sizes?.large?.source_url || sizes?.full?.source_url || 'https://via.placeholder.com/150';
    //
    // let url = post?._embedded?.['wp:featuredmedia']?.[0]?.media_details?.sizes?.large?.source_url
    //     || 'https://via.placeholder.com/150';
    // return url;
};
